$(document).ready(function () {
	
	$(document).on("click", ".btn-delete-item", function(e) {
		
		e.preventDefault();
		
		var destination_url = $(this).data('success-url');
		var redirect_url 	= $(this).data('redirect-url');
		var redirect_target	= $(this).data('redirect-target');
		var token 			= $(this).data('token');
		var holder 			= $(this).closest('.existing-file');
		
		bootbox.confirm({
			title: "Really Delete?",
			message: 'This action is not reversable.',
			callback: function(result) {
				
				if (result == true) {
	
					token = $('input[name="_token"]').val();
						
					$.post(destination_url, { '_token': token} , function( data ) {
	
						// redirect if we have a redirect URL
	
						if(redirect_url && redirect_url.length)
						{
							
							if(redirect_target && redirect_target.length)
								$(redirect_target).load(redirect_url);
							else
								window.location.replace(redirect_url);
							
						}
						
						holder.fadeOut();
						
					});
				}
				
			},
			buttons: {
				confirm: {
					label: "Delete",
					className: "btn-danger",
				},
				cancel: {
					label: "Cancel",
					className: "btn-link",
				},
			}
			
		});
	
	});
	
	// update idea status
	
	$(document).on("click", ".btn-update-status", function(e) {
		
		// load report form
		
		var idea_id = $(this).data('idea-id');
	
		$.get('/admin/idea/get/update-status', { 'idea_id': idea_id }, function( data ) {
	
			bootbox.confirm({
				title: "Status Update",
				message: data,
				callback: function(result) {
					
					if (result == true) {
					
						$.post('/admin/idea/save/update-status', { 
							'idea_id': idea_id,
							'status_id': $('#update_status').val(),
							'send_updates': $('#send_updates').prop('checked'),
							'status_email_text': $('#status_email_text').val(),
							'_token': $('input[name="_token"]').val() 
						} , function( data ) {
							
							window.location.replace('/admin/idea/edit/' + idea_id + '#admininfo');
							location.reload();
							
						});
						
					}
					
				},
				buttons: {
					confirm: {
						label: "Update Status",
						className: "btn-primary",
					},
					cancel: {
						label: "Cancel",
						className: "btn-default",
					},
				}
				
			});
		  
		});
		
	});
	
	// add new manager to campaign
	
	$(document).on("click", ".btn-add-manager", function(e) {
		
		e.preventDefault();
		
		// load report form
		
		var campaign_id = $(this).data('campaign-id');

		$.post('/admin/campaigns/add-manager', { 
			'campaign_id': campaign_id,
			'user_id': $('#campaign_user_id').val(),
			'_token': $('input[name="_token"]').val() 
		} , function( data ) {
			
			$('#managerstablewrap table').load('/admin/campaigns/edit/' + campaign_id + ' #managerstablewrap table');
			
		});
		
	});
	
	// delete manager from campaign
	$(document).on("click", ".btn-delete-manager", function(e) {
		
		e.preventDefault();
		
		// load report form
		
		var campaign_id = $(this).data('campaign-id');
		var user_id 	= $(this).data('user-id');
		
		$.post('/admin/campaigns/delete-manager', { 
			'campaign_id': campaign_id,
			'user_id': user_id,
			'_token': $('input[name="_token"]').val() 
		} , function( data ) {
			
			$('#managerstablewrap table').load('/admin/campaigns/edit/' + campaign_id + ' #managerstablewrap table');
			
		});
					
	});
	
		// add new criteria to campaign
	
	$(document).on("click", ".btn-add-criteria", function(e) {
		
		e.preventDefault();
		
		// load report form
		
		var campaign_id = $(this).data('campaign-id');

		$.post('/admin/campaigns/add-criteria', { 
			'campaign_id': campaign_id,
			'criteria_name': $('#criteria_name').val(),
			'_token': $('input[name="_token"]').val() 
		} , function( data ) {
			
			$('#criteriatablewrap table').load('/admin/campaigns/edit/' + campaign_id + ' #criteriatablewrap table');
			
		});
		
	});
	
	// delete critera from campaign
	$(document).on("click", ".btn-delete-criteria", function(e) {
		
		e.preventDefault();
		
		// load report form
		
		var campaign_id = $(this).data('campaign-id');
		var criteria_id 	= $(this).data('criteria-id');
		
		$.post('/admin/campaigns/delete-criteria', { 
			'campaign_id': campaign_id,
			'criteria_id': criteria_id,
			'_token': $('input[name="_token"]').val() 
		} , function( data ) {
			
			$('#criteriatablewrap table').load('/admin/campaigns/edit/' + campaign_id + ' #criteriatablewrap table');
			
		});
					
	});
	
	// save status settings
	
	$(document).on("click", ".btn-save-small", function(e) {
		
		e.preventDefault();
		
		// load report form
		
		var destination_url = $(this).data('destination-url');
		var return_url		= $(this).data('return-url');
		var form_name 		= $(this).data('form-name');
		var update_row		= $(this).data('row-id');
		var append			= $(this).data('append');
		var update_inplace	= $(this).data('update-inplace');
	
		$.get(destination_url, function( data ) {
	
			bootbox.confirm({
				title: "Save",
				message: data,
				callback: function(result) {
					
					if (result == true) {
						
						$.post(destination_url, $(form_name).serialize())
						
							.done(function( data ) {
								
								if(update_inplace && update_inplace == 1)
								{
								
									if(append && append.length)
									{
									
										$("<div>").load(return_url + ' ' + update_row + data.id, function() {
										    $(append).append($(this).html());
										});
										
									}
									else
										$(update_row).load( return_url + ' ' + update_row + ' > td' );
										
								}
								else
								{
									
									$(update_row).load( return_url + ' ' + update_row );
									
								}
								
							});
						
					}
					
				},
				buttons: {
					confirm: {
						label: "Save",
						className: "btn-primary",
					},
					cancel: {
						label: "Cancel",
						className: "btn-default",
					},
				}
				
			}).init(function() { 
		        
		        //$('#report-url').html(report_url);
		        
		    });
		  
		});
		
	});

});


// generic functions used throughout

// read more links used throughout
	
$(document).on("click", ".readmorelink", function(e) {
		
	e.preventDefault();
	$(this).parent('p').html($(this).parent('p').find('.readmoretext').html());
	$(this).hide();
	
});

// auto complete substring matcher

var substringMatcher = function(strs) {
  return function findMatches(q, cb) {
    var matches, substringRegex;

    // an array that will be populated with substring matches
    matches = [];

    // regex used to determine if a string contains the substring `q`
    substrRegex = new RegExp(q, 'i');

    // iterate through the pool of strings and for any string that
    // contains the substring `q`, add it to the `matches` array
    $.each(strs, function(i, str) {
      if (substrRegex.test(str.fullname)) {
        matches.push(str);
      }
    });

    cb(matches);
  };
};

$(document).ready(function () {
	$(".limit-numeric").keydown(function (e) {
	    // Allow: backspace, delete, tab, escape, enter and .
	    if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 110, 190]) !== -1 ||
	         // Allow: Ctrl+A
	        (e.keyCode == 65 && e.ctrlKey === true) ||
	         // Allow: Ctrl+C
	        (e.keyCode == 67 && e.ctrlKey === true) ||
	         // Allow: Ctrl+X
	        (e.keyCode == 88 && e.ctrlKey === true) ||
	         // Allow: home, end, left, right
	        (e.keyCode >= 35 && e.keyCode <= 39)) {
	             // let it happen, don't do anything
	             return;
	    }
	    // Ensure that it is a number and stop the keypress
	    if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
	        e.preventDefault();
	    }
	});
});


// this is for fancy browse buttons

$(document).on('change', '.btn-file :file', function() {
  var input = $(this),
      numFiles = input.get(0).files ? input.get(0).files.length : 1,
      label = input.val().replace(/\\/g, '/').replace(/.*\//, '');
  input.trigger('fileselect', [numFiles, label]);
});

$(document).ready( function() {
    $('.btn-file :file').on('fileselect', function(event, numFiles, label) {
        
        var input = $(this).parents('.input-group').find(':text'),
            log = numFiles > 1 ? numFiles + ' files selected' : label;
        
        if( input.length ) {
            input.val(log);
        } else {
            if( log ) alert(log);
        }
        
    });
});